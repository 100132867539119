@import 'styles/includes.scss';

.PopupCard {
    position: fixed;
    width: calc(100vw - 32px);
    height: auto;

    top: 50%;
    transform: translate3d(0, -50%, 0);
    z-index: $z-cookiebar;

    @include media(m) {
        transform: none;
        top: auto;
        bottom: 32px;
        left: 16px;
        max-width: 400px;
    }

    @include media(l) {
        bottom: 16px;
        left: 16px;
        max-width: 400px;
    }

    &__Content {
        width: calc(100vw - 32px);
        padding: 32px;

        background: white;
        box-shadow: $boxShadowDropup;

        @include media(m) {
            // margin: 16px;
            top: unset;
            bottom: 32px;
            left: 16px;
            max-width: 400px;
        }

        @include media(l) {
            bottom: 16px;
            left: 16px;
            max-width: 400px;
        }
    }

    &__Text {
        @extend %body-copy-small;

        a {
            color: $colorBlack70;
        }
    }

    &__Text + .Button {
        margin-top: 24px;
    }
}
