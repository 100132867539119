$hw-aspect-ratio: 0.582;
$portrait-w-to-w-aspect-ratio: 0.435;

$image-max-height: 608px;
$image-portrait-max-width: 479px;

$image-fullscreen-width: 65vw;
$image-fullscreen-height: $image-fullscreen-width * $hw-aspect-ratio;

$image-fullscreen-max-width: 1632px;
$image-fullscreen-portrait-max-width: $image-fullscreen-max-width *
    $portrait-w-to-w-aspect-ratio;
$image-fullscreen-max-height: $image-fullscreen-max-width * $hw-aspect-ratio;

$image-responsive-fullscreen-width-small: 400px;
$image-responsive-fullscreen-width-small--portrait: $image-responsive-fullscreen-width-small *
    $portrait-w-to-w-aspect-ratio;
$image-responsive-fullscreen-height-small: $image-responsive-fullscreen-width-small *
    $hw-aspect-ratio;

$image-responsive-fullscreen-width-large: 600px;
$image-responsive-fullscreen-width-large--portrait: $image-responsive-fullscreen-width-large *
    $portrait-w-to-w-aspect-ratio;

$image-responsive-fullscreen-height-large: $image-responsive-fullscreen-width-large *
    $hw-aspect-ratio;

$thumb-width-resp: 310px;
$thumb-height-resp: $thumb-width-resp * $hw-aspect-ratio;
$thumb-width-resp--portrait: $thumb-width-resp * $portrait-w-to-w-aspect-ratio;

$thumb-width-desktop: 143px;
$thumb-height-desktop: $thumb-width-desktop * $hw-aspect-ratio;
$thumb-width-desktop--portrait: $thumb-width-desktop *
    $portrait-w-to-w-aspect-ratio;
