@import 'styles/includes.scss';

$buttonSize: 56px;

.FeedbackButtons {
    $root: &;

    position: relative;
    display: flex;
    width: 100%;
    height: $buttonSize;
    align-items: center;

    margin-top: 24px;

    @include media(tabletL) {
        width: auto;
        margin-top: 0;
    }

    &__Label {
        font-size: 1.6rem;
        @include media(tabletL) {
            font-size: 1.8rem;
            margin-right: 24px;
        }
    }

    &__ButtonWrapper {
        position: absolute;
        right: 0;

        @include media(tabletL) {
            position: relative;
        }

        display: flex;
        width: ($buttonSize * 2) + 8px;
    }

    &__Button {
        width: $buttonSize;
        height: $buttonSize;

        margin-right: 8px;
        &:last-of-type {
            margin-right: 0;
        }

        border-radius: $buttonSize/2;
        background-color: $colorGrey200;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: $colorGrey200;
        background-image: url('#{$basepath}svg/icon-thumb-up.svg');
        background-size: 50% 50%;
        background-position: center center;

        &--DownVote {
            transform: scale(-1);
        }

        &--HasVoted {
            background-color: $colorGrey400;
        }

        &:disabled {
            cursor: not-allowed;
        }

        &:not(:disabled) {
            &:hover {
                background-color: $colorGrey300;
            }
            &:focus {
                outline: none;
                background-color: $colorGrey400;
            }
        }

        transition: background-color $duration $ease;
    }
}
