@import 'styles/includes.scss';
@import 'variables';

.ImageGallery {
    @extend %container;
    $root: &;

    overflow: hidden;
    margin-bottom: 48px;

    @include media(l) {
        margin-bottom: 80px;
    }

    &--Fullscreen {
        display: block;
        position: absolute;
        overflow: visible;

        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);

        max-width: 100vw;
        margin-bottom: 0;

        text-align: center;
    }

    &__Container {
        position: relative;
        $root: &;

        max-width: 100%;
        height: auto;

        @include media(l) {
            margin: 0 107px;
        }

        &--Fullscreen {
            position: relative;
            width: $image-fullscreen-width;
            @include media(xxl) {
                @media (min-height: 1070px) {
                    width: $image-fullscreen-max-width;
                }
            }

            display: inline-block;
            overflow: visible;
            margin: 0;
        }
    }

    &__Wrapper {
        $root: &;
        position: relative;
        display: none;

        &--SingleImage {
            display: block;

            padding-bottom: 56.25%;
            background-color: #eee;

            .ImageGallery__Image {
                position: absolute;
            }

            @include media(l) {
                padding-bottom: 0;
                .ImageGallery__Image {
                    position: relative;
                }
            }
        }

        @include media(l) {
            display: flex;
            justify-content: center;

            background-color: $colorGrey800;

            width: 100%;
            min-width: 100%;
            max-width: 100%;

            height: $image-max-height;
            min-height: $image-max-height;
        }

        &--Fullscreen {
            min-width: auto;
            width: $image-fullscreen-width;
            max-width: 100%;

            min-height: auto;
            height: $image-fullscreen-height;
            max-height: 100%;

            @include media(xl) {
                @media (min-height: 1070px) {
                    width: $image-fullscreen-max-width;
                    height: $image-fullscreen-max-height;
                }
            }
        }
    }

    &__ArrowRight,
    &__ArrowLeft {
        position: absolute;
        right: -16px;
        top: 50%;
        transform: translateY(-50%);

        width: 40px;
        height: 40px;

        box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.3);
        background-color: #fff;
        transition: background-color $duration $ease;

        &:hover {
            background-color: $colorGrey200;
        }

        &:focus {
            outline: none;
            background-color: $colorGrey600;
        }

        border-radius: 50%;
        cursor: pointer;
        border: none;
        display: none;

        @include media(m) {
            display: block;
        }

        &:after {
            content: '';
            display: block;

            width: 16px;
            height: 16px;

            margin: 0 auto;
            background-size: contain;
            background-position-x: 1px;
            background-image: url('#{$basepath}svg/icon-chevron-right.svg');
        }
    }

    &__ArrowLeft {
        right: auto;
        left: -16px;

        &:after {
            transform: rotateZ(-180deg);
        }
    }

    &__Heading {
        @extend %h5;

        display: none;
        @include media(l) {
            display: block;
        }

        padding-bottom: 11px;
        margin-bottom: 16px;

        border-bottom: 2px solid rgba(0, 0, 0, 0.12);
        text-transform: uppercase;
    }

    &__Image {
        position: relative;

        width: 100%;
        height: 100%;

        &--Clickable {
            cursor: pointer;
            &:focus {
                opacity: 0.4;
                outline: none;
            }
        }

        &--Portrait {
            max-width: $image-portrait-max-width;
            min-width: $image-portrait-max-width;
            width: $image-portrait-max-width;

            height: 100%;
        }

        #{$root}--Fullscreen &--Portrait {
            min-width: auto;
            width: 100%;
            max-width: $image-fullscreen-portrait-max-width;
        }
    }

    &__ImageDescription {
        text-align: left;
        &__Description {
            color: black;
        }
    }

    &__ThumbnailsWrapper--Fullscreen
        .ImageGallery__Thumbnails::-webkit-scrollbar {
        width: 0 !important;
    }

    &__ThumbnailsWrapper {
        display: flex;

        &--Fullscreen .ImageGallery__Thumbnails {
            overflow-x: auto !important;
            scroll-behavior: smooth;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: none;
        }
    }

    &__Thumbnails {
        display: flex;
        align-items: flex-start;
        padding-top: 16px;

        overflow-x: scroll;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: none;

        @include media(l) {
            overflow-x: hidden;
        }

        &__MoreAvailable {
            @extend %button-titles;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $colorYellow;
            width: calc(100% - #{(16px + $thumb-width-desktop) * 5});
            height: $thumb-height-desktop;
        }
    }

    // hide scrollbars in edge
    @include edge-only() {
        &:not(&--Fullscreen) &__Thumbnails:after {
            display: block;
            position: absolute;
            bottom: 0;
            content: ' ';
            height: 16px;
            width: 100%;
            background-color: white;
        }
    }
}
