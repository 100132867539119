@import 'styles/includes.scss';

.ShareBookmarksButton {
    &__Container {
        margin-bottom: 24px;
    }

    &__Notice {
        margin: 0;
        padding: 16px;
        width: 100%;
        overflow: hidden;

        &__Url {
            display: block;
            width: 100%;

            margin-top: 16px;
            margin-bottom: 16px;

            word-wrap: break-word;
            font-style: italic;
            color: $colorBlue;
        }
    }
}
