@import 'styles/includes.scss';

.Modal {
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;

    z-index: $z-cookiebar + 1;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.7);

    &__Inner {
        position: relative;
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__CloseButton {
        position: absolute;

        right: 8px;
        top: 8px;

        width: 40px;
        height: 40px;

        transition: background-color $duration $ease;
        background: #fff url('#{$basepath}svg/icon-close.svg');
        background-size: 50% 50%;
        background-position: center center;
        background-repeat: no-repeat;

        box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.3);
        border: none !important;
        border-radius: 50%;

        z-index: 20;
        cursor: pointer;

        &:hover {
            background: $colorGrey200 url('#{$basepath}svg/icon-close.svg');
            background-size: 50% 50%;
            background-position: center center;
            background-repeat: no-repeat;
        }

        &:focus {
            outline: none;
            background: $colorGrey400 url('#{$basepath}svg/icon-close.svg');
            background-size: 50% 50%;
            background-position: center center;
            background-repeat: no-repeat;
        }
    }
}
