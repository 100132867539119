@import 'styles/includes.scss';

.Breadcrumbs {
    &__Container {
        @extend %container;
    }

    &__ListWrap {
        position: relative;

        &:after {
            @include media(m) {
                content: initial;
            }
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 100%;
            background: linear-gradient(
                to left,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
        }
    }

    &__List {
        display: block;
        position: relative;
        white-space: nowrap;

        overflow-x: scroll;

        @include media(m) {
            overflow: hidden;
        }
    }

    &__Item {
        padding: 8px 0;
        margin-right: 8px;
        display: inline-block;

        &:after {
            @extend %img-caption;
            content: '/';
            display: inline-block;
            margin-left: 8px;
        }

        &:last-of-type:after {
            content: unset;
        }

        &:before {
            content: unset;
        }
    }

    &__Link,
    &__Last {
        @extend %img-caption;
        color: $colorBlack70;
        text-decoration: none;
    }
}
