@import 'styles/includes.scss';

.StreamField {
    $root: &;

    &__Component--Cards {
        &:last-child {
            margin-bottom: 56px;
        }
    }

    &__Component--PlaceSection {
        margin: 40px auto;

        @include media(m) {
            margin-bottom: 56px;
        }
    }

    &--Component--PostSegment--FullWidth {
        margin-bottom: 0;
    }

    // Remove padding Components with same color are next to each other
    &__Component--BlackOnYellow + #{$root}__Component--BlackOnYellow,
    &__Component--WhiteOnBlue + #{$root}__Component--WhiteOnBlue,
    &__Component--BlackOnWhite + #{$root}__Component--BlackOnWhite {
        & > section {
            padding: 0 0 50px 0;

            @include media(l) {
                padding: 0 0 60px 0;
            }
        }
    }

    &__Component--CampaignPreamble + #{$root}__Component--CampaignCta {
        & > section {
            margin-top: -30px;
        }
    }

    &__Component--CampaignPostSegment {
        & > section {
            padding-top: 0;
            padding-bottom: 0;

            & > h2 {
                padding-top: 60px;
                margin-bottom: 0;
            }
        }
    }

    // TODO: Investigate this
    & .Map {
        @extend %container;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 80px;
        width: 50%;
        height: 500px;
    }
}
