@import 'styles/includes.scss';

$trans-duration: 200ms;
$trans-easing: ease-out;

$perspective: 600px;
$initial-offset: -30px;
$rotate-amount: -45deg;
$initial-scale: 0.95;

.HingeFadeInFromTop {
    &-enter {
        opacity: 0;
        transform-origin: top center;
        transform: perspective($perspective) scale($initial-scale)
            translate3d(0, $initial-offset, 0) rotate3d(1, 0, 0, $rotate-amount);
    }

    &-enter-active {
        opacity: 1;
        transform-origin: top center;
        transform: perspective($perspective) scale(1) translate3d(0, 0, 0)
            rotate3d(1, 0, 0, 0deg);
        transition: opacity $trans-duration $trans-easing,
            transform $trans-duration $trans-easing;
    }

    &-exit {
        opacity: 1;
        transform-origin: top center;
        transform: perspective($perspective) scale(1) translate3d(0, 0, 0)
            rotate3d(1, 0, 0, 0deg);
    }

    &-exit-active {
        opacity: 0;
        transform-origin: top center;
        transform: perspective($perspective) scale($initial-scale)
            translate3d(0, $initial-offset, 0) rotate3d(1, 0, 0, $rotate-amount);
        transition: opacity $trans-duration $trans-easing,
            transform $trans-duration $trans-easing;
    }
}
