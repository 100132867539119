@import 'styles/includes.scss';

.Sponsors {
    margin-top: 60px;

    &__Container {
        @extend %container;

        @include media(l) {
            max-width: 1046px;
        }
    }

    &__Heading {
        @extend %small-labels-semibold;
        padding-bottom: 8px;
        border-bottom: 2px solid $colorBlack12;
        margin-bottom: 24px;
    }

    &__List {
        display: flex;
        margin-bottom: 80px;
        flex-direction: row-reverse;
    }

    &__Link {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        color: $colorBlack70;

        &--Text {
            position: relative;
        }
    }

    &__ListItem {
        position: relative;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        height: 140px;
        width: 140px;
        margin-left: 24px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: content-box;
        text-align: center;
        justify-content: center;

        &--Text {
            width: auto;
            text-align: right;
            align-items: flex-start;
        }

        &--EuLogo {
            background-image: url('#{$basepath}svg/logotype-european-union.svg');
        }
    }
}
