@import 'styles/includes.scss';

.FeedbackModal {
    @extend %container-text;

    &__Content {
        align-self: center;
        justify-self: center;
        text-align: right;

        width: 100%;
        height: auto;

        padding: 18px 24px 24px;

        @include media(m) {
            padding: 28px 88px;
        }

        @include media(l) {
            padding: 47px 123px;
        }

        border-radius: $cardBorderRadius;
        background-color: $colorYellow;
    }

    &__Title {
        @extend %h4;
        margin-bottom: 24px;
        text-align: center;
        width: 100%;
        display: block;
    }

    &__Textarea {
        @extend %navigation-sub-titles;
        padding: 16px;
        border: none;
        width: 100%;
        height: 96px;
        @include media(m) {
            min-width: 400px;
        }

        @include media(l) {
            min-width: 480px;
        }
    }

    &__SubmitButton {
        width: auto;
        margin-top: 8px;
        padding: 12px 38px;

        @extend %button-titles-small;
        display: inline-block;

        background-color: white;
        &:hover {
            background-color: $colorGrey300;
        }
        &:focus {
            outline: none;
            background-color: $colorGrey400;
        }
        transition: background-color $duration $ease;

        border: 2px solid black;
    }
}
