@import 'styles/includes.scss';

.ShareButton {
    width: 100%;

    &__Button {
        width: 100%;
    }

    &__Modal {
        align-self: center;
        justify-self: center;

        width: auto;
        min-width: 305px;
        @include media(m) {
            min-width: 340px;
        }
        height: auto;
        padding: 18px 24px 24px 24px;

        border-radius: $cardBorderRadius;
        background-color: white;
    }

    &__ModalContent {
        @extend %body-copy;
    }

    &__ModalTitle {
        margin-bottom: 16px;
    }

    &__ModalUrlInput {
        position: relative;
        width: 100%;
    }

    &__Input {
        @extend %navigation-sub-titles;
        width: 100%;

        background-color: $colorGrey200;
        &:focus {
            outline: none;
            background-color: $colorGrey300;
        }

        padding: 16px;

        border: none;
        border-radius: 2px;
    }

    &__CopyButton {
        position: absolute;
        width: 110px;
        height: calc(100% - 16px);
        margin: 8px;
        padding: 0 16px;

        right: 0;
        top: 0;

        @extend %button-titles-small;

        background-color: $colorGrey200;
        &:hover {
            background-color: $colorGrey300;
        }
        &:focus {
            outline: none;
            background-color: $colorGrey400;
        }
        transition: background-color $duration $ease;

        border: 2px solid black;

        &__Labels {
            position: relative;
            width: 100%;
            height: 100%;
            display: inline-flex;
            justify-content: center;
        }

        &__Label {
            align-self: center;
            justify-self: center;

            position: absolute;
        }
    }

    &__ModalSocialButtons {
        margin-bottom: 32px;
        display: inline-flex;
    }

    &__SocialButton {
        position: relative;

        text-decoration: none;

        width: 64px;
        height: 64px;
        border-radius: 64px;

        padding: 8px;
        margin-right: 16px;

        @include media(m) {
            width: 64px;
            height: 64px;

            padding: 8px;
        }

        &:focus {
            outline: none;
            background-color: $colorGrey300;
        }

        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-origin: content-box;

        &__Icon {
            position: absolute;

            width: 36px;
            height: 36px;

            top: 50%;
            left: 50%;
            &--Messenger {
                top: 60%;
                left: 60%;
            }
            transform: translate3d(-50%, -50%, 0);

            svg {
                overflow: visible;
                fill-opacity: 1;

                path {
                    fill: white;
                    fill-opacity: 1;
                }

                g {
                    fill: white;
                    fill-opacity: 1;
                }

                width: 100%;
                height: 100%;
            }
        }

        &--Fb {
            background-color: #3d43b2;
        }

        &--Messenger {
            background-color: #0084ff;
        }

        &--Email {
            background-color: $colorYellow;
        }
    }
}
