@import 'styles/includes.scss';
@import '../../variables';

.ImageGalleryThumbnail {
    $root: &;

    position: relative;
    display: flex;
    justify-content: center;

    width: $thumb-width-resp;
    height: $thumb-height-resp;

    min-width: $thumb-width-resp;
    min-height: $thumb-height-resp;

    max-width: $thumb-width-resp;
    max-height: $thumb-height-resp;

    &--Active:after {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        height: 5px;
        width: 100%;
        background-color: $colorYellow;
    }

    &:focus {
        outline: none;
    }

    &:focus &__Image {
        @include media(sm) {
            opacity: 0.4;
            background-color: $colorGrey600;
            outline: none;
        }

        @media (focus: none) {
            opacity: 1;
        }
    }

    @include media(l) {
        width: $thumb-width-desktop;
        height: $thumb-height-desktop;

        min-width: $thumb-width-desktop;
        min-height: $thumb-height-desktop;

        max-width: $thumb-width-desktop;
        max-height: $thumb-height-desktop;
    }

    background-color: $colorGrey800;
    border: none;
    cursor: pointer;

    &:not(:last-of-type) {
        margin-right: 16px;
    }

    &__Container {
        position: relative;

        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;

        &--Portrait {
            position: static;
            width: $thumb-width-resp--portrait;
            max-width: $thumb-width-resp--portrait;
            width: $thumb-width-resp--portrait;

            @include media(l) {
                width: $thumb-width-desktop--portrait;
                max-width: $thumb-width-desktop--portrait;
                width: $thumb-width-desktop--portrait;
            }
        }

        .BackgroundImage {
            $root: &;

            max-width: 100%;
            max-height: 100%;
        }
    }

    &__Image {
        width: 100%;
        height: 100%;
    }
}
