@import 'styles/includes.scss';

.AuthorCard {
    $root: &;
    position: relative;

    &__Link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
    }

    &__Container {
        @extend %container;
        @include media(l) {
            max-width: 1046px;
        }
        display: flex;

        @include media(m) {
            padding: 24px 0;
            border-top: 2px solid $colorGrey300;
        }
    }

    &__Image {
        position: relative;
        width: 64px;
        height: 64px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;

        @include media(m) {
            width: 120px;
            height: 120px;
            margin-right: 40px;
        }
    }

    &__Content {
        flex: 1 1 0;
        position: relative;
        padding-right: 54px;
    }

    &__Tag {
        @extend %small-labels;
        color: $colorBlack70;
    }

    &__Title {
        @extend %body-copy;
        line-height: 1.2;
        color: $colorBlack;
    }

    &__Text {
        @extend %body-copy-small;
        display: none;
        margin-top: 8px;

        @include media(m) {
            display: block;
        }
    }

    &__ExpandableText {
        @extend %body-copy-small;
        margin-top: 8px;
    }

    &__Toggle {
        position: absolute;
        right: 0;
        top: 13px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        background-color: $colorGrey100;
        background-image: url('#{$basepath}svg/icon-plus.svg');
        background-repeat: no-repeat;
        background-position: center;
        #{$root}--expanded & {
            background-image: url('#{$basepath}svg/icon-minus.svg');
        }

        @include media(m) {
            display: none;
        }
    }
}
