@import 'styles/includes.scss';

.ShareFeedbackSegment {
    @extend %container;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    @include media(l) {
        max-width: 1046px;
    }

    @include media(tabletL) {
        flex-direction: row;
    }

    @include media(tabletL) {
        margin-bottom: 40px;
    }

    &--Campaign {
        margin-bottom: 50px;
        margin-top: 50px;

        @include media(tabletL) {
            margin-top: 60px;
            margin-bottom: 60px;
        }
    }

    .BookmarkButton {
        width: 100%;
        margin-bottom: 16px;
        margin-right: 0;

        @include media(m) {
            width: 50%;
            margin-right: 16px;
            margin-bottom: 0;
        }

        @include media(tabletL) {
            margin-right: 32px;
            width: auto;
        }
    }

    &__Container {
        display: flex;
        width: 100%;

        &--Share {
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;

            @include media(m) {
                flex-wrap: nowrap;
            }
            @include media(tabletL) {
                width: 100%;
            }
        }

        &--Feedback {
            align-items: center;

            @include media(tabletL) {
                justify-content: flex-end;
                width: 70%;
            }
        }
    }

    &__ShareWrapper {
        width: 100%;

        @include media(m) {
            width: 50%;
        }

        @include media(tabletL) {
            width: auto;
        }
    }
}
