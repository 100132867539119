@import 'styles/includes.scss';
@import '../../variables';

.ImageGalleryFullscreenCaption {
    position: relative;

    display: flex;
    width: $image-responsive-fullscreen-width-small;
    @include media(m) {
        width: $image-responsive-fullscreen-width-large;
    }

    margin: 0 auto;
    margin-top: 8px;

    color: white;

    transition: opacity 300ms ease-in-out;

    opacity: 1;
    &--Hidden {
        opacity: 0;
    }

    &__Caption {
        @extend %small-labels;
        width: 80%;

        // padding-left: 8px;
        margin-left: 15px;

        text-align: left;

        &:before {
            position: absolute;
            left: 0px;
            @extend %h5;
            margin-right: 10px;
            border: none;
            content: 'i';
            color: $colorBlack;
        }
    }

    &__Pager {
        width: 20%;
        text-align: right;
        @extend %small-labels;

        padding-right: 8px;
    }
}
