@import 'styles/includes.scss';

.PartnerInformation {
    @extend %container-text;
    background-color: $colorGrey050;
    margin-bottom: 24px;

    @include media(m) {
        margin-bottom: 80px;
    }

    &__Header {
        @extend %preamble;
        margin-bottom: 8px;
    }

    &__Info {
        padding: 40px;
        border-bottom: 1px solid $colorBlack10;
    }

    &__Item {
        margin-bottom: 8px;
    }

    &__LinkWrap {
        padding: 40px;
        text-align: center;
    }

    &__ListLink {
        @extend %standard-link-small;
        display: inline-flex;
        align-items: center;
    }

    &__Text {
        @extend %img-caption;
        padding-left: 20px;
    }

    &__Link {
        @extend %button-titles;
        text-decoration: none;
        background-color: $colorYellow;
        font-weight: 600;
        display: flex;
        justify-content: center;
        padding: 16px 0;
        transition: background-color 0.2s;

        &:hover {
            background-color: darken($colorYellow, 7);
        }
    }

    &__Icon {
        display: inline-block;
        background-size: contain;
        height: 15px;
        width: 15px;
        margin-right: 5px;

        &--Marker {
            background-image: url('#{$basepath}svg/icon-map-marker.svg');
        }

        &--Phone {
            background-image: url('#{$basepath}svg/icon-phone.svg');
        }

        &--Mail {
            background-image: url('#{$basepath}svg/icon-mail.svg');
        }

        &--Arrow {
            height: 17px;
            width: 17px;
            margin-right: 0;
            margin-left: 5px;
            background-image: url('#{$basepath}svg/icon-diagonal-arrow.svg');
        }
    }
}
