@import 'styles/includes.scss';

.ImageGalleryPager {
    position: absolute;
    z-index: 2;
    top: 24px;
    right: 8px;

    @include media(l) {
        top: 8px;
        right: 8px;
    }

    display: flex;

    &--Responsive-only {
        @include media(l) {
            display: none;
        }
    }

    &--Desktop-only {
        @include media(l) {
            display: flex;
        }
    }

    &__CurrentPage {
        width: auto;
        min-width: 67px;
        height: 33px;

        padding: 0 10px;

        line-height: 35px;
        text-align: center;

        @extend %button-titles-small;

        background-color: white;
    }
}
