@import 'styles/includes.scss';

.BookmarkPage {
    &__Container {
        @extend %container-text;
        min-height: 70vh;
    }

    &__Title {
        margin-top: 80px;
        margin-bottom: 24px;
        @extend %h2;
    }

    &__Preamble {
        @extend %preamble;
        padding-bottom: 16px;
        margin-bottom: 24px;

        border-bottom: 2px solid $colorBlack12;
    }

    &__ShareError {
        @extend %preamble;
        margin-bottom: 24px;
    }
}
