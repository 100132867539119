@import 'styles/includes.scss';
@import '../../variables';

.ImageGalleryResponsiveFullscreenImage {
    $root: &;
    position: relative;

    width: auto;
    height: auto;

    display: flex;
    justify-content: center;
    flex-direction: column;

    border: none;
    cursor: pointer;

    margin-bottom: 25px;
    &:not(:first-of-type) {
        margin-left: 32px;
    }

    &:first-of-type {
        margin-left: calc(50% - #{$image-responsive-fullscreen-width-small/2});
    }

    &:last-of-type {
        padding-right: calc(
            50% - #{$image-responsive-fullscreen-width-small/2}
        );
    }

    @include media(m) {
        &:first-of-type {
            margin-left: calc(
                50% - #{$image-responsive-fullscreen-width-large/2}
            );
        }

        &:last-of-type {
            padding-right: calc(
                50% - #{$image-responsive-fullscreen-width-large/2}
            );
        }
    }

    &__Container {
        position: relative;

        width: $image-responsive-fullscreen-width-small;
        height: $image-responsive-fullscreen-height-small;

        min-width: $image-responsive-fullscreen-width-small;
        min-height: $image-responsive-fullscreen-height-small;

        max-width: $image-responsive-fullscreen-width-small;
        max-height: $image-responsive-fullscreen-height-small;

        @include media(m) {
            width: $image-responsive-fullscreen-width-large;
            height: $image-responsive-fullscreen-height-large;

            min-width: $image-responsive-fullscreen-width-large;
            min-height: $image-responsive-fullscreen-height-large;

            max-width: $image-responsive-fullscreen-width-large;
            max-height: $image-responsive-fullscreen-height-large;
        }

        background-color: $colorGrey300;

        &--Portrait {
            .BackgroundImage {
                left: 50%;
                transform: translate3d(-50%, 0, 0);

                width: $image-responsive-fullscreen-width-small--portrait;
                max-width: $image-responsive-fullscreen-width-small--portrait;
                width: $image-responsive-fullscreen-width-small--portrait;

                @include media(m) {
                    width: $image-responsive-fullscreen-width-large--portrait;
                    max-width: $image-responsive-fullscreen-width-large--portrait;
                    width: $image-responsive-fullscreen-width-large--portrait;
                }
            }
        }

        .BackgroundImage {
            $root: &;
            max-width: 100%;
            max-height: 100%;
        }
    }

    &__ScrollAnchor {
        width: $image-responsive-fullscreen-width-small;
        min-width: $image-responsive-fullscreen-width-small;
        max-width: $image-responsive-fullscreen-width-small;

        @include media(m) {
            width: $image-responsive-fullscreen-width-large;
            min-width: $image-responsive-fullscreen-width-large;
            max-width: $image-responsive-fullscreen-width-large;
        }
    }
}
