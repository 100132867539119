@import 'styles/includes.scss';
@import '../../variables';

.ImageGalleryFullscreenButton {
    position: absolute;
    top: 8px;
    right: 82px;

    z-index: 2;

    &__Button {
        width: 32px;
        height: 33px;

        border: none;
        background-color: white;
        transition: background-color $duration $ease;

        &:hover {
            outline: none;

            //$colorBlack08;
            background-color: mix($colorBlack, white, 8%);
        }

        &:focus {
            outline: none;

            //$colorBlack12;
            background-color: mix($colorBlack, white, 12%);
        }

        svg {
            margin-top: 4px;

            width: 16px;
            height: 16px;

            svg path {
                fill: black;
            }
        }
    }
}
